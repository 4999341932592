import 'mdn-polyfills/NodeList.prototype.forEach';
import Shortcut from './Shortcut';

document.addEventListener('DOMContentLoaded', () => {
  const shortcuts = document.querySelectorAll('[data-shortcut]');
  if (!shortcuts.length) {
    return;
  }
  shortcuts.forEach((element) => {
    const shortcut = new Shortcut(element);
    shortcut.mount();
  });
});
