import 'mdn-polyfills/NodeList.prototype.forEach';
import Toggler from '../toggler/Toggler';
import Navigation from '../navigation/Navigation';
import Banner from '../banner/Banner';
import vhCheck from 'vh-check';

export default class Header {
  constructor(element) {
    this.element = element;
    this.navigationTogglers = [];
    this.height = this.element.offsetHeight;
    this.hasResized = true;
    this.tmpWidth = 0;
    this.hasScrolled = true;
    this.tmpScroll = document.documentElement.scrollTop;
    this.lastDcrollDown = document.documentElement.scrollTop;
    this.navigation = null;
    this.navigationToggler = null;
    this.burger = null;
    this.banner = null;
    this.direction = 'down';
    this.isPinned = false;
    this.isSticky = false;
    this.hasNavigationActive = false;

    this.handleNavigationHide = this.handleNavigationHide.bind(this);
    this.handleNavigationShow = this.handleNavigationShow.bind(this);
    this.handleNavigationUpdate = this.handleNavigationUpdate.bind(this);
    this.handleBannerUpdate = this.handleBannerUpdate.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handlePinned = this.handlePinned.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  handleNavigationHide() {
    this.element.classList.remove('has-navigation-active');
    this.hasNavigationActive = false;

    this.navigation.togglers.forEach((toggler) => {
      toggler.hide(true);
    });

    this.navigationToggler.focus();
    this.navigationToggler = null;
    this.updateNavigation();
  }

  handleNavigationShow(e) {
    this.navigationToggler = e.detail.toggler.element;
    this.element.classList.add('has-navigation-active');
    this.hasNavigationActive = true;
    this.navigation.showFirst();
    this.updateNavigation();
  }

  handleNavigationUpdate() {
    this.updateNavigation();
  }

  handleBannerUpdate() {
    if(!this.banner.state) {
      this.banner.element.removeEventListener('banner:update', this.handleBannerUpdate);
      this.banner.unmount();
      this.banner.element.remove()
      this.banner = null;
    }

    this.calculate();
  }

  handlePinned() {
    this.element.removeEventListener('transitionend', this.handlePinned);
    this.element.classList.remove('is-pinned');
    this.element.classList.remove('is-leaving');
    this.isPinned = false;
  }

  handleScroll() {
    this.hasScrolled = true;
  }

  handleResize() {
    this.hasResized = true;
  }

  calculate() {
    const vh = vhCheck();
    this.height = this.element.offsetHeight;
    document.documentElement.style.setProperty('--header-height', `${this.height}px`);
    document.documentElement.style.setProperty('--vh-offset', `${vh.offset}px`);
  }

  updateNavigation() {
    let isNavigationHasTogglerExpanded = false;

    this.navigation.togglers.forEach((toggler) => {
      if (!toggler.target.contains(toggler.element)) {
        isNavigationHasTogglerExpanded = toggler.expanded === true ? true : isNavigationHasTogglerExpanded;
      }
    });

    this.calculate();
  }

  updateResize() {
    if (this.tmpWidth !== this.element.offsetWidth) {
      this.height = this.element.offsetHeight;
      this.navigationTogglers.forEach((toggler) => {
        const isTogglable = window.getComputedStyle(this.burger).getPropertyValue('display') !== 'none';

        if (toggler.mounted !== isTogglable) {
          if (isTogglable) {
            toggler.mount();
          } else {
            toggler.unmount();
            this.element.classList.remove('has-navigation-active');
            this.hasNavigationActive = false;
          }
        }
      });

      this.tmpWidth = this.element.offsetWidth;
      this.calculate();
    }
  }

  updateScroll() {
    if(!this.hasNavigationActive && !this.navigation.hasMegaMenu) {
      if(document.documentElement.scrollTop > this.tmpScroll) {
        this.lastDcrollDown = document.documentElement.scrollTop;
        this.direction = 'down';
      } else {
        this.direction = 'up';
      }

      if (document.documentElement.scrollTop > this.height) {
        this.element.classList.add('is-sticky');
        this.isSticky = true;
        this.calculate();
      } else {
        this.element.classList.remove('is-sticky');
        this.element.classList.remove('is-pinned');
        this.isSticky = false;
        this.isPinned = false;
        this.calculate();
      }

      if(this.isSticky) {
        if (this.direction === 'up') {
          const delta = this.lastDcrollDown - document.documentElement.scrollTop;
          if(delta > 150) {
            this.element.classList.add('is-pinned');
            this.isPinned = true;
          }
        } else {
          if(this.isPinned) {
            this.element.addEventListener('transitionend', this.handlePinned);
            this.element.classList.add('is-leaving');
          }
        }
      }

      this.tmpScroll = document.documentElement.scrollTop;
    }
  }

  mount() {
    const banner = this.element.querySelector('#header-banner');
    if (banner) {
      this.banner = new Banner(banner);
      this.banner.element.addEventListener('banner:update', this.handleBannerUpdate);
      this.banner.mount();
    }

    const nav = this.element.querySelector('#navigation');
    if (nav) {
      this.navigation = new Navigation(nav);
      this.navigation.element.addEventListener('navigation:update', this.handleNavigationUpdate);
      this.navigation.mount();
    }

    this.burger = this.element.querySelector('#header-burger');

    this.element.querySelectorAll('[data-header-toggler="navigation"]').forEach((element) => {
      const target = document.getElementById(element.dataset.headerToggler);
      const toggler = new Toggler(element, target);
      this.navigationTogglers.push(toggler);
      element.addEventListener('toggler:hide', this.handleNavigationHide);
      element.addEventListener('toggler:show', this.handleNavigationShow);

      if (
        window.getComputedStyle(this.burger).getPropertyValue('display') !== 'none'
      ) {
        toggler.mount();
      }
    });

    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);

    const loop = () => {
      if (this.hasScrolled) {
        this.updateScroll();
        this.hasScrolled = false;
      }
      if (this.hasResized) {
        this.updateResize();
        this.hasResized = false;
      }

      requestAnimationFrame(loop);
    }

    loop();

    this.element.classList.add('is-mounted');
    this.calculate();
  }

  unmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);

    if(this.navigationTogglers) {
      this.navigationTogglers.forEach((toggler) => {
        toggler.element.removeEventListener('toggler:hide', this.handleNavigationHide);
        toggler.element.removeEventListener('toggler:show', this.handleNavigationShow);
        toggler.unmount();
      });
      this.navigationTogglers = [];
    }

    if (this.navigation) {
      this.navigation.element.removeEventListener('navigation:update', this.handleNavigationUpdate);
      this.navigation.unmount();
      this.navigation = null;
    }

    if (this.banner) {
      this.banner.element.removeEventListener('banner:update', this.handleBannerUpdate);
      this.banner.unmount();
      this.banner = null;
    }

    this.element.classList.remove('is-mounted');
  }
}
