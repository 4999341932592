import 'mdn-polyfills/NodeList.prototype.forEach';
import Form from './Form';

document.addEventListener('DOMContentLoaded', () => {
  const forms = document.querySelectorAll('[data-form]');
  if (!forms.length) {
    return;
  }
  forms.forEach((element) => {
    const form = new Form(element);
    form.mount();
  });
});
