export default class Shortcut {
  constructor(element) {
    this.element = element;
    this.trigger = parseInt(this.element.dataset.shortcut, 10);
    this.state = this.trigger < window.scrollY;

    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll() {
    this.hasScroll = true;
  }

  updateScroll() {
    if ((this.state && this.trigger >= window.scrollY) || (!this.state && this.trigger < window.scrollY)) {
      if(this.state) {
        this.element.classList.remove('is-active');
      } else {
        this.element.classList.add('is-active');
      }

      this.state = !this.state;
    }
  }

  mount() {
    document.addEventListener('scroll', this.handleScroll);

    const loop = () => {
      if (this.hasScroll) {
        this.updateScroll();
        this.hasScroll = false;
      }

      requestAnimationFrame(loop);
    }

    loop();
  }

  unmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }
}
