import 'mdn-polyfills/NodeList.prototype.forEach';
import FormConditional from './FormConditional';

export default class Form {
  constructor(element) {
    this.element = element;
    this.conditionalFields = this.element.querySelectorAll('[data-form-conditional]');
    this.conditionals = [];
  }

  mount() {
    this.conditionalFields.forEach((element) => {
      const conditionalField = new FormConditional(element);
      conditionalField.mount();
      this.conditionals.push(conditionalField);
    });
  }

  unmount() {
    this.conditionals.forEach((conditionalField) => {
      conditionalField.unmount();
    });
    this.conditionals = [];
  }
}
