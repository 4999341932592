import 'mdn-polyfills/NodeList.prototype.forEach';

export default class FormConditional {
  constructor(element) {
    console.debug(element);
    this.element = element;
    this.options = JSON.parse(this.element.dataset.formConditional);
    this.trigger = document.getElementById(this.options.trigger);
    this.input = this.element.querySelector(`[name="${ this.options.target }"]`);
    this.required = this.input.hasAttribute('required');

    this.handleTriggerChange = this.handleTriggerChange.bind(this);
  }

  show() {
    this.element.removeAttribute('hidden');

    if (this.required) {
      this.input.setAttribute('required', '');
    }
  }

  hide() {
    this.element.setAttribute('hidden', '');

    if (this.required) {
      this.input.removeAttribute('required');
    }
  }

  handleTriggerChange() {
    const currentValue = this.trigger.querySelector(`[name="${ this.options.name }"]:checked`).value;

    if (currentValue === this.options.value) {
      this.show();
    } else {
      this.hide();
    }
  }

  mount() {
    this.trigger.addEventListener('change', this.handleTriggerChange);
    this.handleTriggerChange();
  }

  unmount() {
    this.trigger.removeEventListener('change', this.handleTriggerChange);
    this.show();
  }
}
