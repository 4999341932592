import 'mdn-polyfills/NodeList.prototype.forEach';
import Anchors from './Anchors';

document.addEventListener('DOMContentLoaded', () => {
  const anchorsElements = document.querySelectorAll('[data-anchors]');
  if (!anchorsElements.length) {
    return;
  }
  anchorsElements.forEach((element) => {
    const anchors = new Anchors(element);
    anchors.mount();
  });
});
