import 'mdn-polyfills/NodeList.prototype.forEach';
import { setCookie, getCookie } from '../../utilities/cookies';

export default class Banner {
  constructor(element) {
    this.element = element;
    this.id = this.element.id;
    this.state =  false;
    this.closeButtons = this.element.querySelectorAll('[data-banner-close]');

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    setCookie(this.id, '1');
    this.state = false;
    this.element.dispatchEvent(new CustomEvent('banner:update'));
  }


  mount() {
    const hasCookie = getCookie(this.id);

    if(!hasCookie) {
      this.closeButtons.forEach((button) => {
        button.addEventListener('click', this.handleClose);
      });
      this.state = true;
      this.element.dispatchEvent(new CustomEvent('banner:update'));
    } else {
      this.element.dispatchEvent(new CustomEvent('banner:update'));
    }
  }

  unmount() {
    this.closeButtons.forEach((button) => {
      button.addEventListener('click', this.handleClose);
    });
  }
}
