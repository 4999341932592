import Toggler from '../toggler/Toggler';

export default class Anchors {
  constructor(element) {
    this.element = element;
    this.control = this.element.querySelector('[data-anchors-toggler]');
    this.target = document.getElementById(this.control.dataset.anchorsToggler);
    this.toggler = new Toggler(this.control, this.target, true);
    this.hasResized = true;
    this.tmpWidth = 0;

    this.handleResize = this.handleResize.bind(this);
  }

  handleResize() {
    this.hasResized = true;
  }

  updateResize() {
    if (this.tmpWidth !== this.element.offsetWidth) {
      const isTogglable = window.getComputedStyle(this.toggler.element).getPropertyValue('display') !== 'none';

      if (this.toggler.mounted !== isTogglable) {
        if (isTogglable) {
          this.toggler.mount();
        } else {
          this.toggler.unmount();
        }
      }

      this.tmpWidth = this.element.offsetWidth;
    }
  }

  mount() {
    window.addEventListener('resize', this.handleResize);

    const loop = () => {
      if (this.hasResized) {
        this.updateResize();
        this.hasResized = false;
      }

      requestAnimationFrame(loop);
    }

    this.element.classList.add('is-mounted');
    loop();
  }

  unmount() {
    window.removeEventListener('resize', this.handleResize);
    this.element.classList.remove('is-mounted');
  }
}
